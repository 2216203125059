<template>
<v-container fluid class="pl-0 pr-0">
    <v-row justify="center">
        <v-col cols="12" sm="9">
            <PostList />
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "travel",

    metaInfo() {
        return this.meta(this.$options.name, window.location.href);
    },

    components: {
        PostList: () => import("@/components/PostList").then(m => m.default)
    },

    computed: {
        ...mapGetters(["meta"])
    },

    data() {
        return {}
    },
    
    methods: {}
}
</script>

<style>
</style>
